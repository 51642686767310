@import url(https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  -webkit-user-select: none;
  height: 100%;
}

#root {
  max-width: 500px;
  margin: 0 auto;
}

.navbar {
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.App-logo {
  width: 60%;
  height: 130px;
}

.players-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  padding-top: 5%;
  padding-bottom: 30%;
}

.player-module {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #a54657;
  color: #fff;
  height: 60px;
  padding: 0 10%;
  box-sizing: border-box;
  max-width: 500px;
  font-family: 'Kalam', sans-serif;
  font-size: 24px;
}

.player-module:hover {
  cursor: pointer;
}

.player-module > p {
  margin: 0;
  margin-bottom: -5px;
  line-height: 1;
  word-break: break-word;
}

.overlay {
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.7);
}

.add-player-button {
  border-radius: 50%;
  background-color: #454545;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  margin-bottom: 10%;
  align-self: center;
  position: fixed;
  bottom: 0;
  right: 10%;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}

.button:active {
  transform: translateY(2px);
}

.button:hover {
  cursor: pointer;
}

.add-player-button > p {
  margin: 0;
  padding: 0;
  margin-top: -12px;
}

.sort-players-button {
  border-radius: 50%;
  background-color: #454545;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10%;
  align-self: center;
  position: fixed;
  bottom: 0;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}

.add-player-container {
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  max-width: 500px;
}

.add-player-module {
  background-color: #a54657;
  height: 250px;
  width: 80%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin-top: 35%;
}

.add-player-module-title {
  color: #fff;
  font-size: 50px;
  margin: 10px;
  text-align: center;
  word-break: break-word;
}

.add-player-module-name {
  color: #fff;
  font-size: 24px;
  margin: 10px;
  margin-top: -26px;
}

.add-player-module-input {
  height: 40px;
  font-size: 18px;
  width: 80%;
  text-align: center;
}

.add-player-module-input > .outline {
  outline: 2px solid red;
}

.add-player-module-button {
  background-color: #454545;
  color: #fff;
  height: 40px;
  text-align: center;
  width: 150px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.add-score-module-button {
  background-color: #454545;
  color: #fff;
  height: 40px;
  text-align: center;
  width: 150px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.add-score-module {
  background-color: #a54657;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 80%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin-top: 35%;
}

.customize-container {
  display: flex;
  width: 150px;
  justify-content: space-between;
}

.reset-game-button {
  border-radius: 50%;
  background-color: #454545;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10%;
  align-self: center;
  position: fixed;
  bottom: 0;
  left: 10%;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}

.yes-no-module {
  background-color: #a54657;
  height: 250px;
  width: 80%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin-top: 35%;
  box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.2);
}

.yes-no-module-title {
  color: #fff;
  font-size: 24px;
  margin: 10px;
  text-align: center;
}

.delete-user-button {
  border-radius: 50%;
  background-color: #454545;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10%;
  align-self: center;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}

.edit-score-button {
  border-radius: 50%;
  background-color: #454545;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10%;
  align-self: center;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}

.edit-score-container {
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  max-width: 500px;
}

.edit-score-module {
  background-color: #a54657;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  color: #fff;
  box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  max-height: 800px;
}

.edit-score-line-container {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  height: 350px;
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 -7px 9px -7px rgba(0, 0, 0, 0.7);
  padding-bottom: 10px;
}

.edit-score-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 22px;
  min-height: 22px;
}

.edit-score-module-input {
  border: 0;
  outline: none;
  background-color: #a54657;
  color: white;
  text-align: center;
  font-size: 16px;
  width: 50px;
  text-decoration: underline;
  margin-left: 4px;
}

.edit-score-module-input:focus {
  background-color: #fff !important;
  color: black;
}

@media only screen and (min-width: 768px) {
  .add-player-button {
    right: 35%;
  }
  .reset-game-button {
    left: 35%;
  }
}

.player-name {
  width: calc(70% - 20px);
}

.player-score {
  width: 30%;
  text-align: right;
}

.player-round-container {
  margin-bottom: -5px;
  width: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: sans-serif;
  /*margin-bottom: -5px;*/
}

.player-round-container p {
  margin: 0;
  font-size: 20px;
}

.round-above {
  opacity: 0.5;
}

